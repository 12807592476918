import React, { useRef, useState } from "react"
import Tesseract from "tesseract.js"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import { FcOldTimeCamera } from "react-icons/fc"

import {
  Title,
  Button,
  SubTitle,
  BoxShadowWindow,
  Columns,
  Textarea,
  Column,
  LoadingAnimation,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Image To Text",
    link: "/image-to-text/",
  },
]
const seeAlsoArray = ["/online-chart-maker", "/memes-generator"]

function ImageToText(props) {
  let [imageSrc, setimageSrc] = useState("")
  let [textResult, setTextResult] = useState("")
  let [progressText, setprogressText] = useState("")
  let [see, setsee] = useState(false)
  let [btnTet, setbtnTet] = useState("Copy")
  let [seeImage, setseeImage] = useState(false)

  let copyTextRef = useRef("")

  function handleImage(e) {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      if (seeImage === false) {
        setseeImage(true)
      }
      setimageSrc(URL.createObjectURL(img))

      //   seterrorMessege("")
      if (textResult !== "") {
        setTextResult("")
      }
      setsee(false)
      Tesseract.recognize(URL.createObjectURL(img), "eng", {
        logger: m => {
          progress(m)
        },
      })
        .then(({ data: { text } }) => {
          setTextResult(text)
          setprogressText("")
          setsee(true)
        })
        .catch(function (err) {
          //   seterrorMessege(err)
          console.error(err)
        })
    }
  }

  function progress(packet) {
    setprogressText(
      <>
        <LoadingAnimation />
        <div>{packet.status}</div>
        <div>
          {typeof packet.progress === "undefined" ? (
            ""
          ) : (
            <progress value={packet.progress} max="1" />
          )}
        </div>
      </>
    )
  }

  const makeCopy = () => {
    if (textResult !== "") {
      copyTextRef.current.select()
      let copied = document.execCommand("copy")
      if (copied) {
        setbtnTet("Copied")
        setTimeout(function () {
          setbtnTet("Copy")
        }, 1500)
      }
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Image To Text"
        description="Extract all the text from a selected image using tesseract OCR engine."
        keywords={[
          "image to text, photo to text, picture to text, image to text converter,ocr online, scan text from image, pic to text",
        ]}
      />
      {/* <style type="text/css">{`
.boxOpen{background-color: white;border-radius: 6px;box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);display: block;padding: 1.25rem;}.boxOpen{max-width:500px;min-width:280px;margin: 30px auto;}
.textarea{width:100%;height: -webkit-fill-available;}
`}</style> */}
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Image To Text</Title>
        <SubTitle>
          Extract text from an uploaded image (bmp, jpg, jpeg, png, and pbm.)
        </SubTitle>

        <Columns>
          <Column>
            <BoxShadowWindow>
              {seeImage ? (
                <img
                  id="image"
                  src={imageSrc}
                  alt="pic to text"
                  style={{ maxWidth: "100%" }}
                />
              ) : (
                <FcOldTimeCamera size={280} />
              )}
              <input
                id="file"
                type="file"
                className="mt-3"
                onChange={handleImage}
                accept="image/*"
              />
            </BoxShadowWindow>
          </Column>
          <Column>
            <BoxShadowWindow>
              <h2>Output</h2>
              <div>
                <span>Choose a file to start</span>
              </div>
              {progressText}
              <br />
              {see ? (
                <div>
                  <Textarea value={textResult} ref={copyTextRef} readOnly />
                  <br />
                  <Button
                    borderColor="#1da1f2"
                    color="#1da1f2"
                    hoverColor="white"
                    hoverBorderColor="#1da1f2"
                    hoverBackgroundColor="#1da1f2"
                    onClick={makeCopy}
                  >
                    {btnTet}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </BoxShadowWindow>
          </Column>
        </Columns>
        <br />

        <h3>Image To Text Converter</h3>
        <p>
          Free online OCR (Optical Character Recognition), extract text from an
          image, upload image file to convert photo into text. These online
          tools help you to get the text from pictures, supported images (bmp,
          jpg, jpeg, png, and pbm).
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ImageToText
